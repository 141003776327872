import React from 'react';
import AppCategories from '../app-categories';
import {
  useParams,
} from "react-router-dom";


const CategotiesPage = () => {
  const { id } = useParams()
  let element = <AppCategories />
  if (id) {
    element = <AppCategories categoryID={id} />
  }
  return (
    <>
      <title>Каталог седельных тягачей и авто</title>
      <meta name="description" content="Широкий выбор, в продаже тягачи, полуприцепы, коммерческий транспорт и легковые автомобили. Купить в лизинг. Цены с НДС" />
      {element}
    </>
  )
}


export default CategotiesPage;