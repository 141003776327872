import { Link } from "react-router-dom"
import { Card, Col, Image, Button } from 'antd';
import './app-card-product.css';
import noPhoto from '../static/no-photo.png';

function AppCardProduct({ id, title, pictId }) {

    pictId > 0 ? pictId = `https://rest.general-trucks.ru/get_image/?file_id=${pictId}` : pictId = noPhoto;

    return (
        <Col className='product__col'>
            <Link to={`/product/${id}`}>
                <Card
                    hoverable
                    className='product__card'
                    cover={
                        <Image
                            preview={false}
                            className='product__img'
                            src={pictId}
                            alt={title}
                        />
                    }
                >
                    <div>
                        <p>{title}</p>
                    </div>
                    <Button
                        className='product__button'
                        type='primary'>
                        Подробнее
                    </Button>

                </Card>
            </Link>
        </Col>
    );
};

export default AppCardProduct;