import { Carousel, Row, Col, Space, Typography } from 'antd';
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import './app-carousel.css';

const items = [
    {
        key: '1',
        content: (
            <div className='carousel-slide carousel-slide-1'>
                <Row className='content'>
                    <Col>
                        <Space direction="vertical" className='carousel__info'>
                            <Typography.Title level='1'>Европейские седельные тягачи и полуприцепы для грузоперевозок</Typography.Title>
                            <Typography.Text>новые и с пробегом</Typography.Text>
                            <Typography.Text>в наличии и под заказ</Typography.Text>
                            <Link className='carousel__button' to="/catalogue">Подобрать технику</Link>
                        </Space>
                    </Col>
                </Row>
            </div > 
        ),
    },
    {
        key: '2',
        content: (
            <div className='carousel-slide carousel-slide-2'>
                <div className='text-overlay'>
                    <p>ПИКАП FORD <span style={{ color: '#F67828' }}>RANGER</span></p>
                    <p>ОТ <span style={{ color: '#F67828' }}>5 699 000</span> РУБ</p>
                    <p><span style={{ color: '#F67828' }}>АКЦИЯ</span> ДЕЙСТВУЕТ ДО 30 НОЯБРЯ</p>
                </div>
            </div > 
        ),
    },
    {
        key: '3',
        content: (
            <div className='carousel-slide carousel-slide-3'>
                <div className='text-overlay'>
                    <p>ФУРГОН FORD <span style={{ color: '#F67828' }}>TRANSIT T8</span></p>
                    <p>ОТ <span style={{ color: '#F67828' }}>5 999 000</span> РУБ</p>
                    <p><span style={{ color: '#F67828' }}>АКЦИЯ</span> ДЕЙСТВУЕТ ДО 30 НОЯБРЯ</p>
                </div>
            </div > 
        ),
    },
    
]

function AppCarousel() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 650);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 650);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const sliders = items.slice().reverse().map((item) => {
        return (
            <div key={item.key} className='carousel-item'>
                {item.content}
            </div>  
        )
        
    });

    return (
        <div className='main-carousel'>
            <Carousel 
                autoplay 
                autoplaySpeed={3000}
                dots={isMobile}
                arrows={!isMobile}
            >
                {sliders}
            </Carousel>
        </div>
    );
};

export default AppCarousel;