import * as React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  useRouteError
} from 'react-router-dom';
import {
  MainPage,
  ProductPage,
  CategoriesPage,
  ContactsPage,
  PolicyPage,
  AboutCompanyPage
} from './components/pages'
import AppLayout from './components/app-layout';
import NotFoundPage from './components/not-found-page/not-found-page';

function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);
  return (
    <div>
  <NotFoundPage/>
  </div>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AppLayout />} errorElement={<ErrorBoundary />}>
      <Route index element={<MainPage />} />
      <Route path="catalogue/" exact element={<CategoriesPage />} />
      <Route path="product/:title_id" element={<ProductPage />} />
      <Route path="contacts" element={<ContactsPage />} />
      <Route path="personal_information" element={<PolicyPage />} />
      <Route path="aboutus" element={<AboutCompanyPage />} />
      <Route path="link" element={<MainPage />} />
    </Route>
  )
)

createRoot(document.getElementById('root')).render(
  <RouterProvider router={router} />
);