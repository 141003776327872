import React, { useState } from 'react';
import { Select, Row, Col } from 'antd';
import Loupe from "../static/Loupe.svg";
import './app-search.css'
let timeout;
let currentValue;

const getRequest = (value, callback) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  currentValue = value;

  const fake = async () => {
    await fetch(`https://rest.general-trucks.ru/spravochniki/mas/?f_eshop=${value}`)
      .then((response) => response.json())
      .then((d) => {
        if (currentValue === value) {
          const { results } = d;
          const data = results.filter(item => item.gruppi_json_store.includes(112)).map((item) => ({
            value: item.id,
            text: item.title_store,
          }));
          callback(data);
        }
      });
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
};
const SearchInput = (props) => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState();

  const handleSearch = (newValue) => {
    getRequest(newValue, setData);
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleSelect = (newValue) => {
    open(`../product/${newValue}`, '_blank');
  };


  return (
    <Select
      className='main-search'
      suffixIcon={null}
      showSearch
      value={value}
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      onSelect={handleSelect}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
    />
  );
};


const AppSearch = () =>
(
  <>
    <Row className='main-search' justify='space-around' align='bottom' style={{ borderBottom: '1px solid #fff', lineHeight: '30px' }}>
      <Col span={1}>
        <div>
          <img src={Loupe} alt='' width={18} />
        </div>
      </Col>
      <Col md={18} xs={14}><SearchInput /></Col>
      <Col md={3} xs={7} className=''>
        <span style={{
          fontFamily: 'Inter !important',
          fontSize: '24px',
          fontWeight: 300,
          color: '#fff',
        }}
        >Поиск</span>
      </Col>
    </Row>
  </>
)


export default AppSearch;
