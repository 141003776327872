import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Form, Input, Typography, ConfigProvider, Checkbox, Col, Row } from 'antd';
import './call-back-button.css';
import CloseIcon from '../static/close-icon.svg';
import InputMask from 'react-input-mask';
import SuccessTag from '../static/success-tag.svg';

const SuccessTagMessage = () => {
  return (
    <div>
      <img src={SuccessTag} alt='' width={'86px'} />
    </div>
  )
};

const ModalCloseIcon = () => {
  return (
    <div>
      <img width={'20px'} alt='' src={CloseIcon} />
    </div>
  )
};

export default function CallBackButton() {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  //succes-modal
  const [modal, contextHolder] = Modal.useModal();
  const openSuccessModal = () => {
    let secondsToGo = 5;
    const instance = modal.success({
      className: 'success-modal',
      icon: null,
      closable: true,
      closeIcon: <ModalCloseIcon />,
      footer: null,
      width: 1000,
      content:
        <div>
          <Row justify="center" style={{ paddingTop: 160 }}><Col><SuccessTagMessage /></Col></Row>
          <Row justify="center"><Col>
            <Typography.Text
              style={{
                fontFamily: 'Roboto',
                fontSize: '20px',
                fontWeight: 400,
              }}
            >Благодарим за заявку.</Typography.Text></Col></Row>
          <Row justify="center" style={{ paddingBottom: 160 }}><Col>
            <Typography.Text
              style={{
                fontFamily: 'Roboto',
                fontSize: '20px',
                fontWeight: 400,
              }}>В скором времени с вами свяжется наш менеджер</Typography.Text></Col></Row>
        </div>,
    });
    setTimeout(() => {
      instance.destroy();
    }, secondsToGo * 1000);
  };

  //отправка данных в телеграмм
  const baseTgUrl = 'https://api.telegram.org/bot6905578483:AAG5kAL1MB7UkiHLnM-nXYxRjRYAwtJh010/'

  const sendMessage = async (message) => {
    const url = `${baseTgUrl}sendMessage?chat_id=-4268531369&text=${message}`;
    console.log(url)
    const response = await fetch(url);
    console.log(response);
    if (response.ok) {
      console.log('Success')
    }
    else {
      const errorMessage = `An error has occured: ${response.status}`;
      throw new Error(errorMessage);
    }
  }

  //отправка данных в гугл-таблицы
  const sendToTable = (values) => {
    const formData = new FormData(values.target);
    fetch(`https://script.google.com/macros/s/AKfycbz-fgHqXvSIDzNEw_RFk-tIhL7dN3uidpWHDmg728Jm6D4t18Xq07sPUPH2MT9cQzoUkg/exec?p1=${values.name}&p2=${values.phone}&p3=${window.location.href}`, { 
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        console.log(values)
      });
  };

  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log('Success:', values);
    const message = `Имя: ${values.name}, Телефон: ${values.phone}, страница: ${window.location.href}`
    sendMessage(message);
    sendToTable(values);
    form.resetFields();
    openSuccessModal();
    handleCancel();
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Checkbox: {
              colorPrimary: '#F67828',
              algorithm: true,
              colorBgContainer: 'transparent',
              fontSize: '20px',
            },
            Button: {
              colorPrimary: '#F67828',
            },
            Modal: {
              contentBg: 'rgba(54,50,47, 1)',
            }
          },
        }}
      >
        <Button
          className='call-back-button'
          onClick={showModal}>
          заказать звонок
        </Button>
        <Modal
          className='modal-main'
          open={open}
          onCancel={handleCancel}
          width={1000}
          closeIcon={<ModalCloseIcon />}
          footer={null}
        >
          <Typography.Title className='modal-title'
            style={{
              fontSize: 48
            }}
          >Заказать звонок</Typography.Title>
          <div className='modal-feedback'>
            <Form
              id='callBackForm'
              className='feedback__form'
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              style={{
                maxWidth: 600,
              }}
            >
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, введите Ваше имя!',
                  },
                  {
                    whitespace: true,
                    message: 'Пожалуйста, введите Ваше имя!',
                  },
                ]}
              >
                <Input placeholder="Ваше Имя" />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  // {
                  //   required: true,
                  //   message: 'Пожалуйста, введите Ваш номер телефона!',
                  // },
                  {
                    required: true,
                    validator(rule, value) {
                      return new Promise((resolve, reject) => {
                        const phoneNumber = value.replace(/[^\d]/g, '');
                        const phoneNumberLength = phoneNumber.length;
                        if (phoneNumberLength >= 11) {
                          resolve();
                        } else {
                          reject('Пожалуйста, введите Ваш номер телефона!')
                        }
                      })
                    }
                  },
                ]}
              >
                <InputMask
                  className='phone-input'
                  mask="+7(999) 999-99-99"
                  placeholder="Ваш телефон"
                />
              </Form.Item>
              <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('Подтвердите ознакомление с политикой конфедентиальности')),
                  },
                ]}
              >
                <Checkbox className='feedback-checkbox'>
                  {" "}
                  Я согласен с <Link to='/personal_information'> политикой конфиденциальности</Link>
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Button
                  form='callBackForm'
                  key="submit"
                  type="primary"
                  htmlType="submit"
                  className='submit-button'
                  style={{ width: '100%' }}
                >
                  Отправить
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        {contextHolder}
      </ConfigProvider>
    </>
  );
};