import React, { useEffect, useState, useMemo } from 'react';
import {GeneralService} from '../../service';
import AppCardProduct from '../app-card-product';
import './app-cards.css';

function AppCards({countCards, groupsID, excludeID = null }) {
    const api = useMemo(() => new GeneralService(), []);
    const [cars, setCars] = useState([]);
    const [carsInStock, setCarsInStock] = useState([]);
    // 114 - DAF
    // 117 - Полуприцепы
    // 123 - SCANIA
    // 129 - Легковые
    // 130 - Коммерческие

    useEffect(() => {
        groupsID.map((id) => {
            api.getProducts(id).then((cars) => {
                if (Array.isArray(cars)) {
                    const filteredCars = cars
                        .filter(car => car.published === true)
                        .filter(car => car.id !== excludeID);
                    setCars(prevCars => [...prevCars, ...filteredCars]);
                }
            }); 
        });
    }, []);

    useEffect(() => {
        if (cars.length > 0) {
            const randomCars = [];
            const count = countCards || cars.length;
            while (randomCars.length < count && randomCars.length < cars.length) { 
                const randomIndex = Math.floor(Math.random() * cars.length);
                if (!randomCars.includes(cars[randomIndex])) {
                    randomCars.push(cars[randomIndex]);
                }
            }
            setCarsInStock(randomCars);
        }
    }, [cars]);

    return (
        
        <div className='cars'>
            {carsInStock.map((item, key) => (
                <AppCardProduct key={`${item.id}-${key}`} id={item.id} title={item.title_store} pictId={item.pictures_ids[0]} />
            ))}
        </div>
    );
}

export default AppCards;