import { Typography, Row } from 'antd';

import './app-title.css';

function AppTitle({text, level=2}) {

    return (
        <Row className='content'>
            <Typography.Title key={text} className='title__h2' editable level={level} style={{ margin: 0 }}>
                {text}
            </Typography.Title>
        </Row>
    );
}

export default AppTitle;
