import React from 'react';
import { useParams } from 'react-router-dom';
import AppProduct from '../app-product';

const ProductPage = () => {
  const {title_id}  = useParams();
  
  return (
      <AppProduct key={title_id} title_id={title_id} />
  );
}

export default ProductPage;