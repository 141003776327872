import React from 'react';
import { Link } from 'react-router-dom';
import Feedback3 from '../feedback/feedback3';
import { Breadcrumb, Typography, Row, Image, Card } from 'antd';
import banner from './static/contacts-banner.webp';
import AppMap from '../app-map/app-map';
import AppTitle from '../app-title';
import './contacts-page.css'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';


const { Title } = Typography;


const contacts = [
  {
    id: 1,
    title: 'Тольятти',
    address: 'Самарская область, с. Зелёновка, трасса М5 980 километр, 2',
    linkAddress: 'https://yandex.ru/maps/?ll=49.591295%2C53.515585&mode=routes&rtext=~53.516339%2C49.592028&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D39413067448&z=16',
    sales: '+7 (8482) 43-55-50',
    phone: ' +7 (8482) 61-15-50',
    workingHours: 'Ежедневно с 8:00 до 21:00',
    email: 'service@daf-tlt.ru',
    location: '53.516339 49.592028',
    mapHeight: '845px',
  },
  {
    id: 2,
    title: 'Казань',
    address: 'Республика Татарстан, 784/1 км трассы М7',
    linkAddress: 'https://yandex.ru/maps/?ll=48.851337%2C55.846138&mode=routes&rtext=~55.846071%2C48.851230&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D73934675765&z=17',
    phone: ' +7 (843) 2390 550',
    workingHours: 'Ежедневно с 8:00 до 21:00',
    email: 'service.kzn@general-trucks.ru',
    location: '55.846071 48.851230',
    mapHeight: '271px'
  },
  {
    id: 3,
    title: 'Екатеринбург',
    address: 'Свердловская обл. г. Березовский, тер. Южная промзона 3',
    linkAddress: 'https://yandex.ru/maps/29397/berezovskyi/?ll=60.778257%2C56.891417&mode=routes&rtext=~56.891498%2C60.778075&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D166913775108&z=14',
    phone: ' +7 (8482) 622 550',
    workingHours: 'Ежедневно с 8:00 до 21:00',
    email: 'service.ekat@general-trucks.ru',
    location: '56.891498 60.778075',
    mapHeight: '271px'
  },
  {
    id: 4,
    title: 'Набережные Челны',
    address: 'Республика Татарстан, Мензелинский тракт, д. 56',
    linkAddress: 'https://yandex.ru/maps/236/naberezhnie-chelny/?ll=52.418386%2C55.693953&mode=routes&rtext=~55.693953%2C52.418386&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D90142395796&z=14',
    phone: ' + 7 (8552) 784 550',
    workingHours: 'Ежедневно с 8:00 до 21:00',
    email: 'service.nch@general-trucks.ru',
    location: '55.693953 52.418386',
    mapHeight: '271px'
  },
  {
    id: 5,
    title: 'Уфа',
    address: 'Республика Башкортостан, с. Жуково, ул. Генеральная',
    linkAddress: 'https://yandex.ru/maps/?ll=55.737046%2C54.662844&mode=routes&rtext=~54.662803%2C55.736787&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D105450808831&z=11',
    phone: ' +7 (347) 262-77-55',
    workingHours: 'Ежедневно с 8:00 до 21:00',
    email: 'service.ufa@general-trucks.ru',
    location: '54.662803 55.736787',
    mapHeight: '271px'
  }
]


function ContactCard({ id, title, address, linkAddress, phone, workingHours, email, location, mapHeight, sales }) {

  const coordinates = location.split(" ")

  return (
    <Card className='contact_item'>
      <Title className='title__contacts' editable level={3} style={{ marginTop: '80px' }}>{title}</Title>
      <Link className='text__contacts' to={linkAddress}>{address}</Link>
      {id == 1 &&
        <>
          <p className='text__contacts'>Отдел продаж: <a href='tel:{phone}'>{sales}</a></p>
          <p className='text__contacts'>Сервис: <a href='tel:{phone}'>{phone}</a></p>
        </>
      }
      {id > 1 &&
        <p className='text__contacts'>телефон: <a href='tel:{phone}'>{phone}</a></p>
      }
      {/* <p className='text__contacts'>телефон: <a href='tel:{phone}'>{phone}</a></p> */}
      <p className='text__contacts'>{workingHours}</p>
      <p className='text__contacts' style={{ marginBottom: '30px' }}><a href='mailto:{email}'>{email}</a></p>
      <YMaps>
        <div className='map-block'>
          <Map
            defaultState={{
              center: coordinates,
              zoom: 15,
              controls: ["zoomControl", "fullscreenControl"],
            }}
            width="100%"
            height={mapHeight}
            modules={["control.ZoomControl", "control.FullscreenControl"]}
          >
            <Placemark
              modules={["geoObject.addon.balloon"]}
              defaultGeometry={coordinates}
            />
          </Map>
        </div>
      </YMaps>
    </Card >
  );
};


function ContactCards() {

  const contCard = contacts.map((item) => {
    return <ContactCard className='contact_item' key={item.id} {...item} />
  });

  return (
    <div className='main__contacts content'>
      {contCard}
    </div>

  );
};


function ContactsPage() {

  return (
    <div className='contacts'>
      <title>Контакты и адреса филиалов | General Trucks</title>
      <meta name="description" content="Адреса и телефоны филиалов в Тольятти, Казани, Уфе, Екатеринбурге и Набережных Челнах, с картой, а также время работы и email. Свяжитесь с нами!" />
      <Breadcrumb className='content breadCrumb contact-breadCrumb'
        items={[
          {
            title: <Link to="/">Главная</Link>,
          },
          {
            title: 'Контакты',
          },
        ]}
      />
      <Row className='contacts-banner'>
        <Image preview={false} src={banner} />
      </Row>
      <Row>
        <AppTitle text={'Контакты'} level={1}/>
      </Row>
      <Row><Typography className='contacts__article content' style={{
        fontSize: '40px',
        fontWeight: '300',
        color: '#fff',
        marginTop: '25px',
      }}>ООО "Дженерал Сервис"</Typography></Row>
      <AppMap />
      <Row gutter={40} style={{ margin: 0, display: 'block' }}>
        <ContactCards />
      </Row>
      <Row><Feedback3 /></Row>
    </div>

  )
}

export default ContactsPage;



