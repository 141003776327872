import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, Row, Image, Breadcrumb, Typography, Carousel } from 'antd';

import { GeneralService } from '../../service';
import './app-product.css';

import AppSpinner from '../app-spinner';
import AppCards from '../app-cards/app-cards';
import AppClients from '../app-clients/app-clients';
import AppTitle from '../app-title/app-title';
import Feedback3 from '../feedback/feedback3';
import FeedbackForm  from '../feedback/feedback-form';

const AppProduct = ({ title_id }) => {
    const navigate = useNavigate();
    const id = title_id.split('-').pop();
    const api = useMemo(() => new GeneralService(), []);

    const [productData, setProductData] = useState({
        id: null,
        productName: '',
        productPrice: 0,
        productDescription: '',
        productPict: 0,
        productPictures: [],
        productIdGroup: 0,
        loading: true
    });

    useEffect(() => {
        const fetchData = async () => {
            const productResponse = await api.getProduct(id);

            if (productResponse.length > 0) {
                const product = productResponse[0];

                const pictures = product.pictures_ids.map((image, index) => ({
                    src: `https://rest.general-trucks.ru/get_image/?file_id=${image}`,
                    opacity: index === 0 ? 1 : 0.5
                }));
    
                const nameFormated = product.title_store.replace(/(\d{4})\s(г\.)/g, "$1\u00A0$2");
    
                const description = product.desc_store !== ''
                    ? product.desc_store
                    : '<span>Описание временно отсутствует</span>';
    
                const priceFormated = (product.rekomend_cena_za_ed + "")
                    .split("")
                    .reverse()
                    .join("")
                    .replace(/(\d{3})/g, "$1 ")
                    .split("")
                    .reverse()
                    .join("")
                    .replace(/^ /, "")
                    .split(".")[0];
                
                const productIdGroup = JSON.parse(product.gruppi_json_store).pop();

                setProductData(prevData => ({
                    ...prevData,
                    id: product.id,
                    productName: nameFormated,
                    productPrice: priceFormated,
                    productDescription: description,
                    productPictures: pictures,
                    productIdGroup: productIdGroup,
                    loading: false,
                }));

                const translatedTitle = transliterate(nameFormated).toLowerCase();
                navigate(`/product/${translatedTitle}-${id}`, { replace: true });
            }
        };

        fetchData();
    }, [api, id, navigate]);

    const transliterate = (text) => {
        const cyrillicToLatinMap = {
            'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo',
            'ж': 'zh', 'з': 'z', 'и': 'i', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm',
            'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
            'ф': 'f', 'х': 'kh', 'ц': 'ts', 'ч': 'ch', 'ш': 'sh', 'щ': 'sch',
            'ъ': '', 'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'yu', 'я': 'ya',
            'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ё': 'Yo',
            'Ж': 'Zh', 'З': 'Z', 'И': 'I', 'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M',
            'Н': 'N', 'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U',
            'Ф': 'F', 'Х': 'Kh', 'Ц': 'Ts', 'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Sch',
            'Ъ': '', 'Ы': 'Y', 'Ь': '', 'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya',
            ' ': '-', ',': '', '.': '', '/': '-', '&': 'and'
        };
    
        const result = text.split('').map(char => {
            if (cyrillicToLatinMap.hasOwnProperty(char)) {
                return cyrillicToLatinMap[char];
            } else {
                return char;
            }
        }).join('');

        return result;
    };

    const handleImageClick = (index) => {
        const updatedPictures = productData.productPictures.map((picture, i) => ({
            ...picture,
            opacity: i === index ? 1 : 0.5
        }));

        setProductData(prevData => ({
            ...prevData,
            productPict: index,
            productPictures: updatedPictures
        }));
    };

    const carouselRef = React.createRef();

    const [activeImageIndex, setActiveImageIndex] = useState(0);
    
    const handleImageChange = (index) => {
        setActiveImageIndex(index);
    };

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpand = () => {
        setIsExpanded(true);
    };

    if (productData.loading) {
        return <AppSpinner className='loading' />;
    }
        
    return (
        <>
            <Breadcrumb
                className='content breadCrumb'
                items={[
                    {
                        title: <Link to="/">Главная</Link>,
                    },
                    {
                        title: <Link
                        to="/catalogue"
                        >Каталог</Link>,
                    },
                    {
                        title: productData.productName,
                    },
                ]}
                separator="/"
            />
            {window.innerWidth < 750 ? (
                <>
                <Row className='content'>
                    <Row className='product__name'>
                        <h1>{productData.productName}</h1>
                    </Row>
                    <div className="mobile-gallery">
                        <Carousel 
                            dots={false} 
                            afterChange={handleImageChange} 
                            className="product__carousel"
                            ref={carouselRef}
                        >
                            {productData.productPictures.map((image, index) => (
                                <div key={index}>
                                    <img 
                                        src={image.src} 
                                        alt={`Image ${index}`} 
                                        className="product__image"
                                    />
                                </div>
                            ))}
                        </Carousel>
                        <div className='product__points'>
                            {productData.productPictures.map((_, index) => (
                                <span
                                    key={index}
                                    className={`product__point ${activeImageIndex === index ? 'active' : ''}`}
                                    onClick={() => carouselRef.current.goTo(index, false)}
                                />
                            ))}
                        </div>
                    </div>
                    <Row className='brief__info'>
                        <p>{productData.productPrice} ₽ <span style={{ opacity: 0.5 }}>с НДС</span></p>
                        <p>Автомобиль в наличии, в г. Тольятти</p>
                    </Row>
                </Row>
                <Typography.Title key={'description'} className='product__h3 content' editable level={3} >
                    Описание
                </Typography.Title>
                <Row gutter={80} className="content description">
                    <Col>
                        <div 
                            className={`description__text ${isExpanded ? 'expanded' : 'collapsed'}`} 
                        >
                            <div dangerouslySetInnerHTML={{ __html: productData.productDescription }}></div>
                            <p style={{ marginBottom: 0, marginTop: 50 }}><b>General Trucks (ООО «Дженерал Сервис»)</b></p>
                            <p>Работаем с 2013 года в сфере грузовой техники, с начала 2024 года дополнительно занимаемся продажей лёгкого коммерческого транспорта и легковых автомобилей. 
                            Наши услуги и предложения включают:</p>

                            <p style={{ marginBottom: 0 }}><b>Грузовики и полуприцепы:</b></p>
                            <p>- Продажа новых и подержанных грузовиков от ведущих производителей: DAF и Scania.<br />
                            - Полуприцепы Schmitz и Krone.</p>

                            <p style={{ marginBottom: 0 }}><b>Лёгкий коммерческий транспорт:</b></p>
                            <p>- Продажа цельнометаллических фургонов Ford. </p>

                            <p style={{ marginBottom: 0 }}><b>Легковые автомобили:</b></p>
                            <p>- Продажа автомобилей Mercedes-Benz, Ford, Volkswagen и Toyota.</p>

                            <p style={{ marginBottom: 0 }}><b>Сервисное обслуживание DAF:</b></p>
                            <p>- Качественное обслуживание в пяти центрах: Тольятти, Казань, Набережные Челны, Екатеринбург и Уфа.<br />
                            - Современные сервисные установки и высококачественные запчасти.</p>

                            <p><b>General Trucks</b>  — ваш надежный партнер в приобретении и обслуживании коммерческой техники и легковых автомобилей.</p>

                            <p>Контакты центрального офиса:<br />
                            Тел.: +7 8482 61 15 50<br />
                            Отдел продаж.: +7 8482 43 55 50</p>
                        </div>
                        {!isExpanded && (
                            <div className="description__overlay">
                                <button className="description__toggle-button" onClick={toggleExpand}>
                                    Развернуть
                                </button>
                            </div>
                        )}
                    </Col>
                </Row>
                <Row className='feedback__product'>
                    <p>Отправьте заявку<br />для <span style={{ color: '#F67828' }}>бесплатной</span> консультации</p>
                    <div style={{width:'85%'}}>
                        <FeedbackForm />
                    </div>
                </Row>
                </>
            ) : (
                <>
                <Row className='content product__info'>
                    <Col className='all-photos__info'>
                        <Carousel 
                            dots={false} 
                            arrows 
                            afterChange={handleImageClick} 
                            className="product__carousel"
                            ref={carouselRef}
                        >
                            {productData.productPictures.map((image, index) => (
                                <div key={index}>
                                    <img src={image.src} alt={`Image ${index}`} />
                                </div>
                            ))}
                        </Carousel>
                        <div className='product__photos'>
                            {productData.productPictures.map((picture, index) => (
                                <Image
                                    key={index}
                                    src={picture.src}
                                    onClick={() => carouselRef.current.goTo(index, false)}
                                    alt='photo'
                                    preview={false}
                                    style={{ opacity: picture.opacity }}
                                />
                            ))}
                        </div>
                    </Col>

                    <Col className='brief__info'>
                        <h1>{productData.productName}</h1>
                        <p>{productData.productPrice} ₽ <span style={{ opacity: 0.5 }}>с НДС</span></p>
                        <p>Автомобиль в наличии, в г. Тольятти</p>
                        <p>Отправьте заявку<br />для <span style={{ color: '#F67828' }}>бесплатной</span> консультации</p>
                        <div style={{width:'60%'}}><FeedbackForm /></div>
                    </Col>
                </Row>
                <Typography.Title key={'description'} className='product__h3 content' editable level={3} >
                    Описание
                </Typography.Title>
                <Row gutter={80} className='content description'>
                    <Col>
                        <div dangerouslySetInnerHTML={{ __html: productData.productDescription }}></div>
                    </Col>
                    <Col>
                        <p style={{ marginBottom: 0 }}><b>General Trucks (ООО «Дженерал Сервис»)</b></p>
                        <p>Работаем с 2013 года в сфере грузовой техники, с начала 2024 года дополнительно занимаемся продажей лёгкого коммерческого транспорта и легковых автомобилей. 
                        Наши услуги и предложения включают:</p>

                        <p style={{ marginBottom: 0 }}><b>Грузовики и полуприцепы:</b></p>
                        <p>- Продажа новых и подержанных грузовиков от ведущих производителей: DAF и Scania.<br />
                        - Полуприцепы Schmitz и Krone.</p>

                        <p style={{ marginBottom: 0 }}><b>Лёгкий коммерческий транспорт:</b></p>
                        <p>- Продажа цельнометаллических фургонов Ford. </p>

                        <p style={{ marginBottom: 0 }}><b>Легковые автомобили:</b></p>
                        <p>- Продажа автомобилей Mercedes-Benz, Ford, Volkswagen и Toyota.</p>

                        <p style={{ marginBottom: 0 }}><b>Сервисное обслуживание DAF:</b></p>
                        <p>- Качественное обслуживание в пяти центрах: Тольятти, Казань, Набережные Челны, Екатеринбург и Уфа.<br />
                        - Современные сервисные установки и высококачественные запчасти.</p>

                        <p><b>General Trucks</b>  — ваш надежный партнер в приобретении и обслуживании коммерческой техники и легковых автомобилей.</p>

                        <p>Контакты центрального офиса:<br />
                        Тел.: +7 8482 61 15 50<br />
                        Отдел продаж.: +7 8482 43 55 50</p>
                    </Col>
                </Row>
                </>
            )}

            <Row className='notification content'>
                <p>Вся представленная на сайте информация, касающаяся наличия на складе, технических характеристик, фотографий техники, носит информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями пункта 2 статьи 437 Гражданского кодекса РФ. Заполнение заявок не накладывает на владельцев сайта никаких обязательств, кроме обеспечения сохранности персональных данных. Эксплуатационные показатели не являются конечными и зависят от выбранной комплектации транспортного средства. Завод-изготовитель оставляет за собой право изменять технические характеристики техники, а также перечень применяемых для ее изготовления комплектующих и их параметры, если указанные решения не изменяют ее назначение.</p>
            </Row>
            <AppTitle text={'Похожие авто'} />
            <Row className='content'>
                <AppCards
                    key={productData.id}
                    countCards={4}
                    groupsID={[productData.productIdGroup]}
                    excludeID={productData.id} />
            </Row>
            <Row className='content'>
                <Col className='button-all-goods'>
                    <Button><Link to="/catalogue">Посмотреть все</Link></Button>
                </Col>
            </Row>
            <AppTitle text={'Сопутствующая техника'} />
            <Row className='content'>
                <AppCards
                    key={productData.id}
                    countCards={4}
                    groupsID={productData.productIdGroup === 117 ? [114, 123, 130] : [117]}
                />
            </Row>
            <Row className='content'>
                <Col className='button-all-goods'>
                    <Button><Link to="/catalogue">Посмотреть все</Link></Button>
                </Col>
            </Row>
            <AppTitle text={'Наши клиенты'} />
            <Row>
                <AppClients />
            </Row>
            <Row><Feedback3 /></Row>
        </>
    );
};

export default AppProduct